import React from 'react';
import { connect } from 'react-redux';

const year = new Date().getFullYear();

const Footer = (props) => {
  return (
    <footer className="footer">
        <hr />
        <ul className="list-inline">
          <li className="list-inline-item">{year} © <a href={`https://carbon-base.com/?utm_source=rog-admin-panel&utm_medium=rog-admin-footer&utm_campaign=rog-admin-${props.brand}`} target="_blank" rel="noopener noreferrer">Carbonbase LLC</a></li>
        </ul>
        <small>Time zone for all dates and times: {props.timeZone}. {process.env.REACT_APP_VERSION && <span>&mdash; Version {process.env.REACT_APP_VERSION}</span>}</small>
    </footer>
  );
};

const mapStateToProps = (state) => ({ timeZone: state.auth.user.timezone, brand: state.auth.user.brand });

export default connect(mapStateToProps)(Footer);
