/* DEPENDENCIES */
import './styles/main.scss';
import 'bootstrap/dist/js/bootstrap.bundle';
import React, { useEffect, useState, Suspense, lazy } from 'react';
import {
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { Provider } from 'react-redux';
/* STORE */
import Store from './reducers';
/* COMPONENTS */
import AppContainer from './components/AppContainer';
import checkRole from './utils/checkRole';
const Disabled = lazy(() => import('./components/assets/Disabled'));
const Error403 = lazy(() => import('./components/assets/Error403'));
const Error404 = lazy(() => import('./components/assets/Error404'));
const Admins = lazy(() => import('./components/AdminUser'));
const AdminsEdit = lazy(() => import('./components/AdminUser.Edit'));
const Bills = lazy(() => import('./components/Bills'));
const Branches = lazy(() => import('./components/Branches'));
const BranchesEdit = lazy(() => import('./components/Branches.Edit'));
const Categories = lazy(() => import('./components/Categories'));
const Credentials = lazy(() => import('./components/Credentials'));
const CredentialsEdit = lazy(() => import('./components/Credentials.Edit'));
const Dashboard = lazy(() => import('./components/Dashboard'));
const Items = lazy(() => import('./components/Items'));
const Loader = lazy(() => import('./components/assets/Loader'));
const Login = lazy(() => import('./components/Login'));
const Payments = lazy(() => import('./components/Payments'));
const PaymentsUpload = lazy(() => import('./components/Payments.upload'));
const ProvidersCheck = lazy(() => import('./components/Providers.check'));
const ProvidersResults = lazy(() => import('./components/Providers.results'));
const Profile = lazy(() => import('./components/Profile'));

const App = () => {
  const [auth, setAuth] = useState(Store.getState().auth.isAuthenticated);
  const [isActive, setIsActive] = useState(Store.getState().auth.user?.is_active === false ? false : true);
  const [brand, setBrand] = useState(true);
  const [redirect] = useState(window.location.pathname + window.location.search);

  useEffect(() => {
    const login = Store.subscribe(() => {
      setAuth(Store.getState().auth.isAuthenticated);
      setIsActive(Store.getState().auth.user.is_active);
      setBrand(Store.getState().brand.is_active);
    });

    return function cleanup() {
      login();
    };
  }, []);

  return (
    <Provider store={Store}>
      <Loader />
      {(brand === false || isActive === false) && auth && <Disabled />}
      {
        auth
          ?
          (
            <AppContainer>
              <Suspense fallback={<div className="loader"><div className="typing_loader"></div></div>}>
                <Routes>
                  <Route path='/' element={<Dashboard />} />
                  <Route path='/admin-users' element={checkRole('admins_read') ? <Admins /> : <Error403 title={'Admins'} perm={'admin_read'} />} />
                  <Route path='/admin-users/create' element={checkRole('admins_write') ? <AdminsEdit /> : <Error403 title={'Admins Create'} perm={'admin_write'} />} />
                  <Route path='/admin-users/edit/:id' element={checkRole('admins_write') ? <AdminsEdit /> : <Error403 title={'Admins Edit'} perm={'admin_write'} />} />
                  <Route path='/bills' element={<Bills />} />
                  <Route path='/branches' element={<Branches />} />
                  <Route path='/branches/create' element={<BranchesEdit />} />
                  <Route path='/branches/edit/:id' element={<BranchesEdit />} />
                  <Route path='/categories' element={<Categories />} />
                  <Route path='/credentials' element={<Credentials />} />
                  <Route path='/credentials/create' element={<CredentialsEdit />} />
                  <Route path='/credentials/edit/:id' element={<CredentialsEdit />} />
                  <Route path='/check' element={<ProvidersCheck />} />
                  <Route path='/results/:id' element={<ProvidersResults />} />
                  <Route path='/items' element={<Items />} />
                  <Route path='/payments' element={<Payments />} />
                  <Route path='/payments/upload' element={<PaymentsUpload />} />
                  <Route path='/profile' element={<Profile />} />
                  <Route path='/login' element={<Login />} />
                  <Route path='*' element={<Error404 />} />
                </Routes>
              </Suspense>
            </AppContainer>
          )
          :
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/*' element={<Navigate to={`/login${(redirect && redirect.indexOf('login') === -1 && redirect !== '/') ? `?path=${redirect}` : ''}`} />} />
          </Routes>
      }
    </Provider >
  );
};

export default App;
