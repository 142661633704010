import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import logo from '../images/isologo-negative.png';
import $ from 'jquery';
import checkRole from '../utils/checkRole';

const Sidebar = (props) => {
    useEffect(() => {
        $(".js__accordion").each(function() {
            var selector = $(this);
            selector.find(".js__control").on("click", function (event) {
                event.preventDefault();
                if ($(this).parent().hasClass("active")) {
                    $(this).parent().removeClass("active");
                    $(this).next().stop().slideUp(400);
                } else {
                    selector.find(".active").children(".js__content").stop().slideUp(400);
                    selector.find(".active").removeClass("active");
                    $(this).parent().addClass("active");
                    $(this).next(".js__content").slideDown(400);
                }
            });
        });

        $(".js__menu_mobile").on("click", function() {
            $("html").toggleClass("menu-active");
            $(window).trigger("resize");
        });

        $(".js__menu_close").on("click", function() {
            $("html").removeClass("menu-active");
        });

        $("body").on("click", function (event) {
            if ($("html.menu-active").length && $(window).width() < 800) {
                var selector = $(event.target);
                if (!(selector.hasClass("main-menu") || selector.hasClass("js__menu_mobile") || selector.parents(".main-menu").length || selector.parents(".js__menu_mobile").length)) {
                    $("html").removeClass("menu-active");
                }
            }
        });
    }, []);

    return (
        <section>
            <div className="main-menu">
                <header className="header">
                    <NavLink to='/' className="logo"><img src={logo} alt="ReachOut" /></NavLink>
                    <button type="button" className="button-close fa fa-times js__menu_close"></button>
                    <div className="user">
                        <NavLink to="/profile" className={`avatar sidebar-profile ${(!props.user.profile_pic || !props.user.profile_pic[0]) && 'bg-gradient-warning d-flex'}`} style={{ backgroundImage: (props.user.profile_pic && props.user.profile_pic[0]) && `url(${props.user.profile_pic[0]})` }}>
                            {
                                (!props.user.profile_pic || !props.user.profile_pic[0])
                                &&
                                <div className="admin-pic-placeholder">
                                    <span className="initials justify-content-center align-self-center">{props?.user?.first_name.split(',').map(n => n[0].toUpperCase())}{props?.user?.last_name.split(',').map(n => n[0].toUpperCase())}</span>
                                </div>
                            }
                            <span className="status online"></span>
                        </NavLink>
                        <h5 className="name"><NavLink to="/profile">{props.user.name}</NavLink></h5>
                        <h5 className="position">{props.user.brand}</h5>
                    </div>
                </header>
                <div className="content">
                    <div className="navigation">
                        <h5 className="title">Application</h5>
                        <ul className="menu js__accordion">
                            <li>
                                <NavLink to="/" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-chart-line"></i><span>Dashboard</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/items" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-tshirt"></i><span>Items</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/bills" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-receipt"></i><span>Bills</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/payments" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-money-check-alt"></i><span>Payments</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/check" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-user"></i><span>Providers</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/categories" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-tags"></i><span>Categories</span></NavLink>
                            </li>
                        </ul>
                        {
                            checkRole('admins_read') &&
                            <div>
                                <h5 className="title">Settings</h5>
                                <ul className="menu js__accordion">
                                    <li>
                                        <NavLink to="/branches" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-store"></i><span>Branches</span></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/credentials" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-key"></i><span>Credentials</span></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/admin-users" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-users-cog"></i><span>Admin Users</span></NavLink>
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = state => ({ user: state.auth.user, brand: state.brand });

export default connect(mapStateToProps)(Sidebar);
