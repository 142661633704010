import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import swal from 'sweetalert2';
import withRouter from './WithRouter';
import Modal from 'react-modal';

Modal.setAppElement(document.getElementById('root'));

const NavBar = (props) => {
    const signout = () => {
        swal.fire({
            title: "Logout?",
            text: "Are you sure you want to logout?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: 'Sign Out',
            cancelButtonText: 'Stay',
            reverseButtons: true,
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn btn-danger ml-2",
                cancelButton: "btn btn-secondary active mr-2",
            }
        }).then(
            (value) => {
                if (value.isConfirmed) {
                    props.logout();
                    props.router.navigate('');
                }
            }
        );
    };

    useEffect(() => {
        $(".js__drop_down_button").on("click", function () {
            if ($(window).width() > 1025) {
                $(".js__drop_down").toggleClass("active");
            }
        });
    });

    return (
        <>
        <div className="fixed-navbar">
            <div className="float-left">
                <button type="button" className="menu-mobile-button fas fa-bars js__menu_mobile"></button>
                <h1 className="page-title">Dux Integration</h1>
            </div>
            <div className="float-right">
                <NavLink to="/profile" className="ico-item notice-alarm"><i className="fas fa-user-cog" /></NavLink>
                <i className="ico-item"></i>
                <span className="ico-item fas fa-power-off" onClick={signout}></span>
            </div>
        </div>
        </>
    );
};

const mapStateToProps = state => ({
    token: state.auth.token,
    brand: state.brand,
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch({ type: 'LOGOUT' })
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavBar));